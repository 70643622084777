/**
 * Calculate the cost per store item
 * @param {Object} obj
 * @param {String} defaultMeasure The measure assignated to the store item in the cms
 * @param {String} measure The measure assignated for the yser in the store
 * @param {Float} weight The average weight of a store item per weight
 * @param {Float} price The price of the store item per the default measure assigned in the cms
 * @param {Integer} quantity The quantity of the store item in the cart
 */
export const calculateStoreItemTotal = ({ defaultMeasure, measure, weight, price, quantity }) => {
  // if default measure != measure means that it has changed from kg to pz or slice to kg.
  // defaultMeasure for piece is kg, and for slice is reb. Therefore the same clause does not apply to both.
  // if it is different means that it is either a piece or slice, if it is a slice means now is kg, and should be measured as unit
  if (defaultMeasure !== measure && defaultMeasure === 'reb') {
    return price * quantity;
    //if it wasnt a slice means it is a piece and should be calculated as weighted
  } else if (defaultMeasure !== measure) {
    return price * quantity * (weight && weight > 0 ? weight : 1.0);
    //clause means it is reb, and since it is same as measure it should be weighted
  } else if (defaultMeasure === 'reb') {
    return price * quantity * (weight && weight > 0 ? weight : 1.0);
    //means it is anything but slice or weighted piece.
  } else {
    return price * quantity;
  }
};

export const calculateItemRealQuantity = ({ defaultMeasure, measure, weight, quantity }) => {
  // if default measure != measure means that it has changed from kg to pz or slice to kg.
  // defaultMeasure for piece is kg, and for slice is reb. Therefore the same clause does not apply to both.
  // if it is different means that it is either a piece or slice, if it is a slice means now is kg, and should be measured as unit
  if (defaultMeasure !== measure && defaultMeasure === 'reb') {
    return quantity;
    //if it wasnt a slice means it is a piece and should be calculated as weighted
  } else if (defaultMeasure !== measure) {
    return quantity * (weight && weight > 0 ? weight : 1.0);
    //clause means it is reb, and since it is same as measure it should be weighted
  } else if (defaultMeasure === 'reb') {
    return quantity * (weight && weight > 0 ? weight : 1.0);
    //means it is anything but slice or weighted piece.
  } else {
    return quantity;
  }
};

/**
 * Sum all storeItemTotals
 * @param {Object} cartItems
 */
export const calculateSubtotal = cartItems =>
  Object.keys(cartItems)
    .map(key => cartItems[key].storeItemTotal)
    .reduce((a, b) => a + b, 0);

/**
 * Calculate total
 * @param {Object} obj
 * @param {Float} obj.subtotal
 * @param {Float} obj.shippingCost
 * @param {Float} obj.discount
 */
export const calculateTotal = ({ deliveryType, subtotal, shippingCost, discount }) =>
  subtotal + (deliveryType === 'shipping' ? shippingCost : 0.0) - discount;

/**
 * Decrease quantity amount in a cart item
 * @param {Object} obj
 * @param {Float} obj.minimum The minimum amount of purchase
 * @param {String} obj.measure The measure selected by the user in store
 * @param {Integer} obj.quantity The quantity selected by the user in the store
 * @param {Integer} obj.weight The weight of the store item
 */
export const decreaseQuantity = ({ minimum = 0.5, measure, weight, quantity }) => {
  const isBigFruit = measure === 'kg' && weight > 1;

  // If measure is pz or mz or if is kg and weight != 1 should decrease by grams
  if (measure === 'pz' || measure === 'mz' || measure === 'caja' || measure === 'reb' || isBigFruit) {
    // If the selected quantity > to the grams should decrease if not should keep the same grams
    quantity = quantity - 1;
  } else {
    // If quantity is < 0.5kg but > 0.125kg shoud decrease / 2
    if (quantity <= 0.5 && quantity > minimum) {
      quantity = quantity * 0.5 > minimum ? quantity / 2 : minimum;
      // If quantity is > 1kg but < 4kg should decrease in 0.5kg
    } else if (quantity > 0.5 && quantity <= 4) {
      quantity = quantity - 0.5;
      // If quantity > 4kg should decrease in 1kg
    } else if (quantity > minimum) {
      quantity = quantity - 1;
    }
  }

  if ((measure === 'kg' || measure === 'reb') && minimum && quantity <= minimum) return minimum;
  else if (measure !== 'kg' && quantity < 1) return 1;
  else return quantity;
};

/**
 * Increase quantity amount in a cart item
 * @param {Object} obj
 * @param {String} obj.measure The measure selected by the user in store
 * @param {Integer} obj.quantity The quantity selected by the user in the store
 * @param {Integer} obj.weight The weight of the store item
 */
export const increaseQuantity = ({ minimum = 0.25, measure, weight, quantity }) => {
  const isBigFruit = measure === 'kg' && weight > 1;

  // If measure is pz or mz or if is kg and != 1 increase by grams
  if (measure === 'pz' || measure === 'mz' || measure === 'caja' || measure === 'reb' || isBigFruit) {
    return quantity + 1;
  } else {
    // Grams measure = kg
    // If measure = kg and < 0.5 kg increase by 0.25kg
    if (quantity < 1) {
      return quantity < 0.25 ? 0.25 : quantity * 2;
      // If quantity < 4kg increase by 0.5kg
    } else if (quantity < 4) {
      return quantity + 0.5;
      // If > 4kg increase by 1kg
    } else {
      return quantity + 1;
    }
  }
};
