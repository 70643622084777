import { ApolloProvider } from '@apollo/client';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import WebSocket from './context/WebSocket';
import './helpers/initFA';
import Main from './Main';
import store from './store';
import apolloClient from './utils/apolloClient';
import './index.scss';
import './swipe-delete.scss';

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <Main>
        <WebSocket>
          <App />
        </WebSocket>
      </Main>
    </ApolloProvider>
  </Provider>,
  document.getElementById('main'),
);
