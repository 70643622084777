import {
  ADD_CART_ITEM,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEMS,
  REMOVE_CART_ITEM,
  EMPTY_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  SET_MEASURE,
  SET_DISCOUNT,
  SET_DISCOUNT_RULES,
  SET_DELIVERY_TYPE,
  SET_DELIVERY_INFO,
  SET_STORE,
  SET_SHIPPING_INFO,
  SET_PICKUP_INFO,
  SET_EXTRA_NOTES,
  SET_PAYMENT_METHOD,
  SET_CASH_OPTION,
  SET_SHIPPING_AVAILABLE,
  SET_SHIPPING_COST,
  SET_SHIPPING_SETTINGS,
  SET_CUSTOMER_PAYMENT_METHODS,
  SET_CUSTOMER_CARD,
  SET_ORDER_STATE,
  ORDER_STATE_INITIALIZED,
  SET_CART_ITEMS_UPDATED,
  MARK_CART_ITEM_AS_CHECK,
  CLEAR_SHOPPING_CART,
  SET_CART_ITEMS,
  SET_CUSTOMER,
  SET_ORDER_MESSAGE,
  SELECT_RIPENESS,
  SET_DELIVERY_SCHEDULE_SLOT,
  SET_DELIVERY_TIME,
  SET_DELIVERY_DAY,
  SET_STRIPE_PAYMENT_METHOD,
  UPDATE_STOCK_CART_ITEM,
} from '../types';

import {
  calculateStoreItemTotal,
  calculateSubtotal,
  calculateTotal,
  increaseQuantity,
  decreaseQuantity,
  calculateItemRealQuantity,
} from '../helpers/shoppingCartReducerHelper';

import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

const storeItemModel = item => {
  return {
    __typename: 'StoreItems',
    id: item?.id,
    name: item?.name,
    measure: item?.measure,
    price: item?.price,
    unit: item?.unit,
    image_url: item?.image_url,
    files: item?.files,
    category: {
      __typename: 'Category',
      name: item?.category?.name,
      id: item?.category?.id,
    },
    subcategory: item?.subcategory,
    isInStock: item?.isInStock,
    minimum: item?.minimum,
    weight: item?.weight,
    description: item?.description,
    has_ripeness: item?.has_ripeness,
  };
};

/**
 * Initial state of shopping cart
 * @param {Object} obj
 * @param {Object} obj.cartItems The products in the shopping cart
 * @param {Object} obj.cartItems.storeItem The store item detail
 * @param {String} obj.cartItems.measure The display measure of a store item
 * @param {Integer} obj.cartItems.quantity The store item quantity
 * @param {Float} obj.cartItems.total The store item detail
 * @param {Array} obj.discountRules The rules to apply discount in a order
 * @param {Array} obj.discountLabels Which kind of discounts are applied
 * @param {Float} obj.subtotal Cart subtotal amount
 * @param {Float} obj.discount Cart discount amount
 * @param {Float} obj.shippingCost Default shipping cost
 * @param {Boolean} obj.hasShippingCost If the store has shipping cost
 * @param {String} obj.shippingCostType Static or dynamic shipping cost
 * @param {Array} obj.shippingCosts Definition of shipping costs
 * @param {Float} obj.total Total amount (subtotal + shipping - discount)
 * @param {String} obj.deliveryType Shipping or pickup
 * @param {String} obj.store The assigned store by shipping coordinates
 * @param {String} obj.deliveryInfo Extra delivery info
 * @param {String} obj.shippingName Name of the person that will receiving the product
 * @param {String} obj.shippingAddress Shipping address of the person that will receiving the product
 * @param {String} obj.shippingLocality City of the shipping address
 * @param {String} obj.shippingCoordinates Latitude and longitude of the address
 * @param {String} obj.shippingWhatsapp Whatsapp of the person that will receiving the product
 * @param {Array} obj.pastShippings The last 5 shippingInfo selected
 * @param {String} obj.pickupDateTime The estimated date time to pickup the order in store
 * @param {String} obj.paymentMethod Card, cash or transfer
 * @param {String} obj.cashOption How much money will pay the user
 * @param {String} obj.extraNotes Notes from the use
 * @param {String} obj.orderState The current state of the order
 * @param {String} obj.orderStateMessage Current state message
 * @param {Array} obj.customerPaymentMethods Stripe payment methods
 * @param {Object} obj.customerCard Customer card detail
 * @param {Object} obj.stripePaymentMethod Customer card detail
 * @param {Object} obj.deliveryDay Shipping delivery day
 * @param {Object} obj.deliveryTime Shipping delivery time
 * @param {Object} obj.deliveryScheduleSlot Shipping delivery schedule slot
 */
export const initialState = {
  cartItems: {},
  cartItemsCount: 0,
  discountRules: [],
  discountLabels: [],
  subtotal: 0.0,
  discount: 0.0,
  shippingAvailable: true,
  shippingCost: 0.0,
  staticShippingCost: 0.0,
  hasShippingCost: false,
  shippingCostType: null,
  total: 0.0,
  store: null,
  deliveryInfo: null,
  deliveryType: 'shipping',
  shippingName: '',
  shippingLocality: '',
  shippingAddress: '',
  shippingCoordinates: null,
  shippingWhatsapp: '',
  shippingExtra: '',
  pickupDateTime: moment().add(2, 'hours'),
  pastShippings: [],
  paymentMethod: '',
  cashOption: '500',
  extraNotes: '',
  orderState: ORDER_STATE_INITIALIZED,
  orderStateMessage: null,
  customerPaymentMethods: [],
  customerCard: null,
  cartItemsUpdated: false,
  orderMessage: '',
  deliveryScheduleSlot: null,
  deliveryTime: '',
  deliveryDay: null,
  stripePaymentMethod: {},
};

/**
 * Reducer that manage all the operations in the shopping cart
 * @param {Object} state
 * @param {Object} action
 */
const shoppingCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART_ITEM: {
      const { weight, price, minimum } = action.payload;
      // If is big fruit add by pz
      const measure = weight && weight > 1 && action.payload.measure === 'kg' ? 'pz' : action.payload.measure;
      const defaultMeasure = action.payload.measure;
      // Wholesale means that a product coulb be added by kgs having weight = 0 and minimum > 1
      const quantity = measure === 'kg' && !weight && minimum > 1 ? minimum : 1;
      let storeItemTotal = calculateStoreItemTotal({ price, weight, defaultMeasure, measure, quantity });
      let calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
      const cartItems = {
        ...state.cartItems,
        [action.payload.id]: {
          storeItem: storeItemModel(action.payload),
          defaultMeasure,
          measure,
          quantity,
          storeItemTotal,
          calculated_quantity,
        },
      };
      const subtotal = calculateSubtotal(cartItems);
      const { deliveryType, shippingCost, discount } = state;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
      const cartItemsCount = (state.cartItemsCount || 0) + 1;
      return {
        ...state,
        cartItems,
        cartItemsCount,
        subtotal,
        total,
      };
    }
    case UPDATE_CART_ITEM: {
      const storeItem = action.payload;
      if (
        state.cartItems[storeItem.id] !== undefined &&
        state.cartItems[storeItem.id].updated_at !== storeItem.updated_at
      ) {
        let cartItems = { ...state.cartItems };

        // if the cart item is inactive and exist remove it
        if (!storeItem.isInStock) {
          delete cartItems[storeItem.id];
          // if the cart item exist, update
        } else {
          const oldCartItem = state.cartItems[storeItem.id];
          const { weight, price } = storeItem;
          const defaultMeasure = storeItem.measure;
          let quantity;
          let measure;
          if (oldCartItem.defaultMeasure !== defaultMeasure) {
            quantity = 1;
            measure = weight && weight > 1 && storeItem.measure === 'kg' ? 'pz' : storeItem.measure;
          } else {
            quantity = oldCartItem.quantity;
            measure = oldCartItem.measure;
          }
          let storeItemTotal = calculateStoreItemTotal({ price, weight, defaultMeasure, measure, quantity });
          let calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
          cartItems[storeItem.id] = {
            storeItem: storeItemModel(storeItem),
            defaultMeasure,
            measure,
            quantity,
            storeItemTotal,
            calculated_quantity,
          };
        }

        const subtotal = calculateSubtotal(cartItems);
        const { deliveryType, shippingCost, discount } = state;
        const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
        const cartItemsCount = Object.keys(cartItems).length;
        return {
          ...state,
          cartItems,
          cartItemsCount,
          subtotal,
          total,
          cartItemsUpdated: false, // Temporary disabled
        };
      } else {
        return { ...state };
      }
    }
    case UPDATE_STOCK_CART_ITEM: {
      const storeItem = action.payload;
      if (state.cartItems[storeItem.id] !== undefined) {
        let cartItems = { ...state.cartItems };

        if (!storeItem.storeItem.isInStock) {
          delete cartItems[storeItem.id];
        } else {
          const oldCartItem = state.cartItems[storeItem.id];
          const { weight, price } = storeItem.storeItem;
          const defaultMeasure = storeItem.storeItem.measure;
          let quantity;
          let measure;
          if (oldCartItem.defaultMeasure !== defaultMeasure) {
            quantity = 1;
            measure = weight && weight > 1 && storeItem.storeItem.measure === 'kg' ? 'pz' : storeItem.storeItem.measure;
          } else {
            quantity = storeItem.quantity;
            measure = oldCartItem.measure;
          }
          let storeItemTotal = calculateStoreItemTotal({ price, weight, defaultMeasure, measure, quantity });
          let calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
          cartItems[storeItem.id] = {
            storeItem: storeItemModel(storeItem.storeItem),
            defaultMeasure,
            measure,
            quantity,
            storeItemTotal,
            calculated_quantity,
          };
        }

        const subtotal = calculateSubtotal(cartItems);
        const { deliveryType, shippingCost, discount } = state;
        const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
        const cartItemsCount = Object.keys(cartItems).length;
        return {
          ...state,
          cartItems,
          cartItemsCount,
          subtotal,
          total,
          cartItemsUpdated: false, // Temporary disabled
        };
      } else {
        return { ...state };
      }
    }
    case UPDATE_CART_ITEMS: {
      const storeItems = action.payload;
      let cartItems = { ...state.cartItems };
      storeItems.forEach(storeItem => {
        if (
          state.cartItems[storeItem.id] !== undefined &&
          state.cartItems[storeItem.id].updated_at !== storeItem.updated_at
        ) {
          // if the cart item is inactive and exist remove it
          if (!storeItem.isInStock) {
            delete cartItems[storeItem.id];
            // if the cart item exist, update
          } else {
            const oldCartItem = state.cartItems[storeItem.id];
            const { weight, price } = storeItem;
            const defaultMeasure = storeItem.measure;
            let quantity;
            let measure;
            let ripeness = oldCartItem.ripeness;
            if (oldCartItem.defaultMeasure !== defaultMeasure) {
              quantity = 1;
              measure = weight && weight > 1 && storeItem.measure === 'kg' ? 'pz' : storeItem.measure;
            } else {
              quantity = oldCartItem.quantity;
              measure = oldCartItem.measure;
            }
            let storeItemTotal = calculateStoreItemTotal({ price, weight, defaultMeasure, measure, quantity });
            let calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
            cartItems[storeItem.id] = {
              storeItem: storeItemModel(storeItem),
              defaultMeasure,
              measure,
              quantity,
              storeItemTotal,
              ripeness,
              calculated_quantity,
            };
          }
        }
      });

      const subtotal = calculateSubtotal(cartItems);
      const { deliveryType, shippingCost, discount } = state;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
      const cartItemsCount = Object.keys(cartItems).length;
      return {
        ...state,
        cartItems,
        cartItemsCount,
        subtotal,
        total,
        cartItemsUpdated: false, // Temporary disabled
      };
    }
    case REMOVE_CART_ITEM: {
      const cartItems = { ...state.cartItems };
      delete cartItems[action.payload];
      const subtotal = calculateSubtotal(cartItems);
      const { deliveryType, shippingCost, discount } = state;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
      const cartItemsCount = (state.cartItemsCount || 0) - 1;
      return {
        ...state,
        cartItems,
        cartItemsCount,
        subtotal,
        total,
      };
    }
    case SET_CART_ITEMS: {
      const cartItems = action.payload;
      const subtotal = calculateSubtotal(cartItems);
      const { deliveryType, shippingCost, discount } = state;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });

      return {
        ...state,
        cartItems,
        cartItemsCount: Object.keys(cartItems).length,
        subtotal,
        total,
      };
    }
    case EMPTY_CART: {
      const cartItems = {};
      const subtotal = calculateSubtotal(cartItems);
      const { deliveryType, shippingCost, discount } = state;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
      const cartItemsCount = 0;
      return {
        ...state,
        cartItems,
        cartItemsCount,
        subtotal,
        total,
      };
    }
    case SET_CART_ITEMS_UPDATED: {
      return {
        ...state,
        cartItemsUpdated: action.payload,
      };
    }
    case MARK_CART_ITEM_AS_CHECK: {
      const cartItems = { ...state.cartItems };
      cartItems[action.payload].updated = false;
      return {
        ...state,
        cartItems,
      };
    }
    case SELECT_RIPENESS: {
      if (state.cartItems[action.payload.id]) {
        const { ripeness } = action.payload;
        const cartItems = {
          ...state.cartItems,
          [action.payload.id]: {
            ...state.cartItems[action.payload.id],
            ripeness,
          },
        };
        return {
          ...state,
          cartItems,
        };
      } else return { ...state };
    }
    case SET_DELIVERY_SCHEDULE_SLOT: {
      return {
        ...state,
        deliveryScheduleSlot: action.payload.deliveryScheduleSlot,
      };
    }
    case SET_DELIVERY_TIME: {
      return {
        ...state,
        deliveryTime: action.payload.deliveryTime,
      };
    }
    case SET_DELIVERY_DAY: {
      return {
        ...state,
        deliveryDay: action.payload.deliveryDay,
      };
    }
    case INCREASE_QUANTITY: {
      if (state.cartItems[action.payload]) {
        const { weight, price } = state.cartItems[action.payload].storeItem;
        const defaultMeasure = state.cartItems[action.payload].storeItem.measure;
        const { measure } = state.cartItems[action.payload];
        let { quantity } = state.cartItems[action.payload];
        quantity = increaseQuantity({
          weight,
          measure,
          quantity,
        });
        const storeItemTotal = calculateStoreItemTotal({
          weight,
          price,
          defaultMeasure,
          measure,
          quantity,
        });
        const calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
        const cartItems = {
          ...state.cartItems,
          [action.payload]: {
            ...state.cartItems[action.payload],
            quantity,
            storeItemTotal,
            calculated_quantity,
          },
        };
        const subtotal = calculateSubtotal(cartItems);
        const { deliveryType, shippingCost, discount } = state;
        const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
        return {
          ...state,
          cartItems,
          subtotal,
          total,
        };
      } else return { ...state };
    }
    case DECREASE_QUANTITY: {
      if (state.cartItems[action.payload]) {
        const { weight, price, minimum } = state.cartItems[action.payload].storeItem;
        const defaultMeasure = state.cartItems[action.payload].storeItem.measure;
        const { measure } = state.cartItems[action.payload];
        let { quantity } = state.cartItems[action.payload];
        quantity = decreaseQuantity({
          minimum,
          weight,
          measure,
          quantity,
        });
        const storeItemTotal = calculateStoreItemTotal({
          weight,
          price,
          defaultMeasure,
          measure,
          quantity,
        });
        const calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
        const cartItems = {
          ...state.cartItems,
          [action.payload]: {
            ...state.cartItems[action.payload],
            quantity,
            storeItemTotal,
            calculated_quantity,
          },
        };
        const subtotal = calculateSubtotal(cartItems);
        const { deliveryType, shippingCost, discount } = state;
        const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
        return {
          ...state,
          cartItems,
          subtotal,
          total,
        };
      } else return { ...state };
    }
    case SET_MEASURE: {
      if (state.cartItems[action.payload.id]) {
        const { weight, price } = state.cartItems[action.payload.id].storeItem;
        const quantity = 1;
        const defaultMeasure = state.cartItems[action.payload.id].storeItem.measure;
        const measure = action.payload.measure;
        const storeItemTotal = calculateStoreItemTotal({
          weight,
          price,
          defaultMeasure,
          measure,
          quantity,
        });
        const calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });

        const cartItems = {
          ...state.cartItems,
          [action.payload.id]: {
            ...state.cartItems[action.payload.id],
            quantity,
            measure,
            storeItemTotal,
            calculated_quantity,
          },
        };

        const subtotal = calculateSubtotal(cartItems);
        const { deliveryType, shippingCost, discount } = state;
        const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
        return {
          ...state,
          cartItems,
          subtotal,
          total,
        };
      } else return { ...state };
    }
    case SET_DISCOUNT_RULES: {
      return {
        ...state,
        discountRules: action.payload,
      };
    }
    case SET_DISCOUNT: {
      const { discount, discountLabels } = action.payload;
      const { deliveryType, subtotal, shippingCost } = state;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
      return {
        ...state,
        discount,
        discountLabels,
        total,
      };
    }
    case SET_DELIVERY_TYPE: {
      const deliveryType = action.payload;

      return {
        ...state,
        deliveryType,
      };
    }
    case SET_STORE: {
      const store = action.payload;

      return {
        ...state,
        store,
      };
    }
    case SET_DELIVERY_INFO: {
      const deliveryInfo = action.payload;

      return {
        ...state,
        deliveryInfo,
      };
    }
    case SET_CUSTOMER: {
      const { shippingName, shippingWhatsapp } = action.payload;
      return {
        ...state,
        shippingName,
        shippingWhatsapp,
      };
    }
    case SET_SHIPPING_INFO: {
      const { shippingLocality, shippingAddress, shippingCoordinates, shippingExtra } = action.payload;

      // Insert pastShippings
      let pastShippings = !!state.pastShippings && Array.isArray(state.pastShippings) ? [...state.pastShippings] : [];

      if (state.shippingAddress && state.shippingCoordinates) {
        pastShippings.splice(0, 0, {
          shippingLocality: state.shippingLocality,
          shippingAddress: state.shippingAddress,
          shippingCoordinates: state.shippingCoordinates,
          shippingExtra: state.shippingExtra,
        });
      }

      // Remove from pastShipping the current shipping address
      pastShippings = pastShippings.filter(pastShipping => pastShipping.shippingAddress !== shippingAddress);
      pastShippings = uniqBy(pastShippings, 'shippingAddress');
      pastShippings = pastShippings.slice(0, 3);

      return {
        ...state,
        pastShippings,
        shippingLocality,
        shippingAddress,
        shippingCoordinates,
        shippingExtra,
      };
    }
    case SET_PICKUP_INFO: {
      const { shippingName, shippingWhatsapp, pickupDateTime } = action.payload;

      return {
        ...state,
        shippingName,
        shippingWhatsapp,
        pickupDateTime,
      };
    }
    case SET_EXTRA_NOTES: {
      return {
        ...state,
        extraNotes: action.payload,
      };
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.payload,
      };
    }
    case SET_CASH_OPTION: {
      return {
        ...state,
        cashOption: action.payload,
      };
    }
    case SET_SHIPPING_AVAILABLE: {
      const shippingAvailable = action.payload;
      return {
        ...state,
        shippingAvailable,
      };
    }
    case SET_SHIPPING_COST: {
      const { deliveryType, subtotal, discount } = state;
      const shippingCost = action.payload;
      const total = calculateTotal({ deliveryType, subtotal, shippingCost, discount });
      return {
        ...state,
        shippingCost,
        total,
      };
    }
    case SET_SHIPPING_SETTINGS: {
      const { staticShippingCost, hasShippingCost, shippingCostType } = action.payload;
      return {
        ...state,
        staticShippingCost,
        hasShippingCost,
        shippingCostType,
      };
    }
    case SET_CUSTOMER_PAYMENT_METHODS: {
      return {
        ...state,
        customerPaymentMethods: action.payload,
      };
    }
    case SET_CUSTOMER_CARD: {
      return {
        ...state,
        customerCard: action.payload,
      };
    }
    case SET_ORDER_STATE: {
      const { orderState, orderStateMessage } = action.payload;
      return {
        ...state,
        orderState,
        orderStateMessage,
      };
    }
    case CLEAR_SHOPPING_CART: {
      return { ...initialState };
    }
    case SET_ORDER_MESSAGE: {
      const { orderMessage } = action.payload;
      return {
        ...state,
        orderMessage,
      };
    }
    case SET_STRIPE_PAYMENT_METHOD: {
      const { stripePaymentMethod } = action.payload;
      return {
        ...state,
        stripePaymentMethod,
      };
    }
    default:
      return { ...state };
  }
};

export default shoppingCartReducer;
