import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { identifyWithSegment, trackWithSegment } from 'helpers/analytics';
import { all, connection } from 'page-timing';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import TTI from 'tti-polyfill';
import { getCLS, getFID, getLCP } from 'web-vitals';
import {
  clearShoppingCart,
  setCartItems,
  setDeliveryType,
  setShippingInfo,
  setCustomer,
  updateCartItems,
} from './actions/shoppingCartActions';
import { setStoreCategories, setStoreItems } from './actions/storefrontActions';
import AppContext from './context/Context';
import { calculateStoreItemTotal, calculateItemRealQuantity } from './helpers/shoppingCartReducerHelper';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './helpers/utils';
import { getBusiness, getStoreCategories } from './queries/storefront';

const findStoreItemsQuery = loader('queries/graphql/storeItems/findStoreItems.graphql');
const findStoreOrder = loader('queries/graphql/storeOrders/findStoreOrder.graphql');

export const getDomain = () => {
  // Production environment
  if (process.env.REACT_APP_STAGE === 'production') {
    return window.location.host;
    // Non production environments
  } else {
    // Get store domain from footer selector
    if (getItemFromStore('storeDomain')) return getItemFromStore('storeDomain');
    // Default for deploy-preview
    else if (process.env.REACT_APP_STAGE === 'deploy-preview') return 'asturias.mercadomty.com';
    // Remove subdomain for staging
    else return window.location.host.replace('staging.', '');
  }
};

export const isDemo = () => {
  return window.location.host.includes('demo.orchata.com') || window.location.host.includes('midemo.mx');
};

/**
 * This class manage the configuration of the store
 * @param {*} props
 */
const Main = ({
  storeItems,
  storeCategories,
  setStoreItems,
  setStoreCategories,
  updateCartItems,
  shippingName,
  shippingWhatsapp,
  setCartItems,
  setShippingInfo,
  setCustomer,
  clearShoppingCart,
  children,
  deliveryType,
  selectedCategory,
}) => {
  const initialState = { settings: { currency: 'mxn' } };
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', false));
  const [isActive, setIsActive] = useState(true);
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', false),
  );
  const [currency, setCurrency] = useState('$');
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalCheckout, setModalCheckout] = useState(false);
  const [store, setStore] = useState(initialState); // TODO: replace by business
  const [branch, setBranch] = useState({}); // TODO: replace by store
  const [demoId, setDemoId] = useState();
  const [facebook, setFacebook] = useState({});
  const [hasSubCategories, setHasSubCategories] = useState(false);
  const [token, setToken] = useState(getItemFromStore('jwt_user_token', false));
  const [isAuthenticated, setIsAuthenticated] = useState(!!getItemFromStore('jwt_user_token', false));
  const [isDashboardSession, setIsDashboardSession] = useState(false);
  const [canSelectStore, setCanSelectStore] = useState(false);

  const [continueShopping, setContinueShopping] = useState(false);

  const [preloadStoreOrder, setPreloadStoreOrder] = useState(null);

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  const [getStoreItems, { data }] = useLazyQuery(findStoreItemsQuery);
  const [getStoreOrder, storeOrderResult] = useLazyQuery(findStoreOrder);

  /**
   * Preload store order
   */
  useEffect(() => {
    if (storeOrderResult.data && preloadStoreOrder && storeItems && storeItems.length > 0) {
      const storeOrder = storeOrderResult.data?.storeOrders?.[0];
      if (storeOrder) {
        // TODO: Set shipping locality
        const shippingName = storeOrder.name;
        const shippingWhatsapp = storeOrder.whatsapp;
        const shippingAddress = storeOrder.shippingAddress;
        const shippingCoordinates = storeOrder.shipping_coordinates;
        const shippingExtra = storeOrder.shipping_extra;

        setShippingInfo({ shippingAddress, shippingCoordinates, shippingExtra });
        setCustomer({ shippingName, shippingWhatsapp });
        setDeliveryType(deliveryType || 'shipping');

        if (preloadStoreOrder.reuseOrder) {
          let cartItems = {};
          storeOrder.store_order_items.forEach(storeOrderItem => {
            const storeItem = storeItems.filter(storeItem => {
              return parseInt(storeItem.id) === storeOrderItem.store_item_id;
            })[0];

            if (storeItem) {
              const defaultMeasure = storeItem.measure;
              const { price } = storeItem;
              const { measure, quantity, weight, ripeness } = storeOrderItem;
              const storeItemTotal = calculateStoreItemTotal({ price, weight, defaultMeasure, measure, quantity });
              const calculated_quantity = calculateItemRealQuantity({ weight, defaultMeasure, measure, quantity });
              cartItems[storeOrderItem.store_item_id] = {
                storeItem,
                defaultMeasure,
                measure,
                quantity,
                storeItemTotal,
                updated: false,
                ripeness,
                calculated_quantity,
              };
            }
          });
          setCartItems(cartItems);
        }
      }
    }
  }, [
    clearShoppingCart,
    setCartItems,
    setCustomer,
    setShippingInfo,
    storeItems,
    preloadStoreOrder,
    storeOrderResult.data,
    deliveryType,
  ]);

  /**
   * Get store items
   */
  useEffect(() => {
    if (data?.storeItems?.length > 0) {
      const actualStoreItems = [...storeItems];
      const incommingStoreItems = data.storeItems.filter(isi => !actualStoreItems.find(si => si.id === isi.id));
      const mergedStoreItems = actualStoreItems.concat(incommingStoreItems);

      //TODO: hardcoding storeId for orchata filter, improve this
      const storeItemsByStore = mergedStoreItems.filter(si =>
        branch?.id ? branch?.id === parseInt(si?.store?.id) : store?.id === 217 ? 59 === parseInt(si?.store?.id) : si,
      );

      setStoreItems(storeItemsByStore);
      updateCartItems(data?.storeItems);
      setItemToStore('products', data?.storeItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setStoreItems, store, updateCartItems, branch]);

  /**
   * Handle message from dashboard
   */
  useEffect(() => {
    const domains = [process.env.REACT_APP_DASHBOARD_HOST];

    window.addEventListener(
      'message',
      event => {
        if (!domains.includes(event.origin)) return;

        const { action, key, value } = event.data;
        if (action === 'save') {
          if (key === 'token') {
            setIsAuthenticated(true);
            setToken(JSON.stringify(value));
            setItemToStore('jwt_user_token', token);
          } else if (key === 'currentStore') {
            setBranch(value);
            setCanSelectStore(false);
          } else if (key === 'storeOrderMessage') {
            clearShoppingCart();
            setPreloadStoreOrder(value);
            getStoreOrder({
              variables: {
                id: value.customer.last_store_order_id,
              },
            });
            setIsDashboardSession(true);
          } else if (key === 'newOrderMessage') {
            clearShoppingCart();
            setIsDashboardSession(true);
          }
        }
      },
      false,
    );
  }, [token, getStoreOrder, clearShoppingCart]);

  /**
   * Get store categories
   */
  useEffect(() => {
    (async () => {
      if (store.id && store.isSaas && storeCategories && storeCategories.length === 0) {
        const storeCategories = await getStoreCategories({ ...store });
        if (storeCategories && storeCategories.length > 0) {
          setStoreCategories(storeCategories);
          setItemToStore('storeCategories', null);
        }
      }
    })();
  }, [setStoreCategories, store, storeCategories]);

  const trackEvent = useCallback(
    (business, shippingName, shippingWhatsapp) => {
      if (shippingWhatsapp) {
        identifyWithSegment({
          whatsapp: shippingWhatsapp,
          storefront: business,
          properties: {
            business_id: business.id,
            business_name: business.Name,
            customer_name: shippingName,
            device: isDashboardSession ? 'CMS' : isMobile ? 'Mobile' : 'Web',
          },
        });
        trackWithSegment({
          event: 'Old Customer Visitor',
          storefront: business,
          properties: {
            business_id: business.id,
            business_name: business.Name,
            device: isDashboardSession ? 'CMS' : isMobile ? 'Mobile' : 'Web',
          },
        });
      } else {
        trackWithSegment({
          event: 'New Visitor',
          storefront: business,
          properties: {
            business_id: business.id,
            business_name: business.Name,
            device: isDashboardSession ? 'CMS' : isMobile ? 'Mobile' : 'Web',
          },
        });
      }
      trackWithSegment({
        event: 'Store Visited',
        storefront: business,
        properties: {
          business_id: business.id,
          business_name: business.Name,
        },
      });
      const send = metrics => {
        if (metrics.largest_contentful_paint) {
          trackWithSegment({
            event: 'Page Loaded',
            storefront: business,
            properties: {
              business_id: business.id,
              business_name: business.Name,
              time_to_load: metrics.largest_contentful_paint / 1000,
            },
          });
        }
      };
      const measure = async () => {
        const connectionInfo = await connection();

        // Send metrics from browser performance API
        send(await all());

        // Send web vitals to the same endpoint
        [
          [getLCP, 'largest_contentful_paint'],
          [getFID, 'first_input_delay'],
          [getCLS, 'cumulative_layout_shift'],
        ].forEach(([fn, name]) =>
          fn(({ value }) =>
            send({
              [name]: value,
              ...connectionInfo, // Some connection info
            }),
          ),
        );

        TTI.getFirstConsistentlyInteractive()
          .then(time_to_interactive =>
            send({
              time_to_interactive,
              ...connectionInfo, // Some connection info
            }),
          )
          .catch(() => null);
      };
      measure();
    },
    [isDashboardSession],
  );

  /**
   * Get business configuration
   */
  useEffect(() => {
    if (!isDemo() || (isDemo() && demoId))
      (async () => {
        const response = await getBusiness({ domain: getDomain(), demoId });
        if (response && response.business) {
          setStore(response.business);
          if (selectedCategory !== 'All') {
            const catId = storeCategories.find(sc => sc.category.name === selectedCategory).category.id;
            getStoreItems({
              variables: Object.assign(
                {
                  business: response.business.id,
                  category: catId,
                },
                response.business.only_featured_store_items ? { featured: true } : {},
              ),
            });
          }
          setItemToStore('store', null);
          setIsActive(response.business.isActive);
          if (response && response.business && response.business.settings) {
            if (response.business.settings.currency === 'crc') setCurrency('₡');
          }
        }
      })();
  }, [demoId, getStoreItems, selectedCategory, storeCategories]);

  const setPixelInit = () => {
    if (facebook && facebook.pixel_init) {
      ReactPixel.init(facebook.pixel_init);
      ReactPixel.pageView();
    }
  };
  useEffect(setPixelInit, [facebook]);

  const trackWithAnalytics = () => {
    if (store.id && store.facebook) {
      setFacebook(store.facebook);
    }
    if (store.id) {
      trackEvent(store, shippingName, shippingWhatsapp);
    }
  };
  useEffect(trackWithAnalytics, [store.id, shippingName, shippingWhatsapp, store, trackEvent]);

  useEffect(() => {
    setStylesheetMode('continueShopping');
    // eslint-disable-next-line
  }, [continueShopping]);

  useEffect(() => {
    setStylesheetMode('isFluid');
    // eslint-disable-next-line
  }, [isFluid]);

  useEffect(() => {
    setStylesheetMode('isRTL');
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  const fetchStoreItems = async ({ page, pageSize = 50 }) => {
    const response = await getBusiness({ domain: getDomain(), demoId });
    if (response && response.business) {
      const catId = storeCategories.find(sc => sc.category.name === selectedCategory).category.id;
      getStoreItems({
        variables: {
          business: response.business.id,
          category: catId,
          start: page * pageSize,
        },
      });
    }
  };

  const value = {
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    showBurgerMenu,
    setShowBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    currency,
    setCurrency,
    store,
    setStore,
    isActive,
    modalCheckout,
    setModalCheckout,
    demoId,
    setDemoId,
    hasSubCategories,
    setHasSubCategories,
    token,
    isAuthenticated,
    preloadStoreOrder,
    isDashboardSession,
    continueShopping,
    setContinueShopping,
    fetchStoreItems,
    branch,
    setBranch,
    canSelectStore,
    setCanSelectStore,
  };

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light,
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

const mapStateToProps = state => ({
  storeItems: state.storefront.storeItems,
  storeCategories: state.storefront.storeCategories,
  cartItems: state.shoppingCart.cartItems,
  cartItemsCount: state.shoppingCart.cartItemsCount,
  shippingName: state.shoppingCart.shippingName,
  shippingWhatsapp: state.shoppingCart.shippingWhatsapp,
  deliveryType: state.shoppingCart.deliveryType,
  selectedCategory: state.storefront.selectedCategory,
});

const mapDispatchToProps = dispatch => ({
  setStoreItems: storeItems => dispatch(setStoreItems(storeItems)),
  setStoreCategories: storeCategories => dispatch(setStoreCategories(storeCategories)),
  updateCartItems: storeItems => dispatch(updateCartItems(storeItems)),
  setCartItems: cartItems => dispatch(setCartItems(cartItems)),
  setShippingInfo: shippingInfo => dispatch(setShippingInfo(shippingInfo)),
  setCustomer: customer => dispatch(setCustomer(customer)),
  setDeliveryType: deliveryType => dispatch(setDeliveryType(deliveryType)),
  clearShoppingCart: () => dispatch(clearShoppingCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);
