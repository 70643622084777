import { createContext, useContext } from 'react';

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
});

export const useAppContext = () => useContext(AppContext);

export const ProductContext = createContext({ products: [] });

export const StoreItemContext = createContext({ storeItems: [] });

export const CategoryContext = createContext({ categories: [], storeCategories: [] });

export default AppContext;
