import {
  SET_STORE_ITEMS,
  UPDATE_STORE_ITEM,
  REMOVE_STORE_ITEM,
  SET_STORE_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUBCATEGORY,
  SET_LAST_STORE_ITEM_POSITION,
  SET_QUERY_FILTER,
} from '../types';

export const setStoreItems = storeItems => ({
  type: SET_STORE_ITEMS,
  payload: storeItems,
});

export const updateStoreItem = storeItem => ({
  type: UPDATE_STORE_ITEM,
  payload: storeItem,
});

export const removeStoreItem = storeItem => ({
  type: REMOVE_STORE_ITEM,
  payload: storeItem,
});

export const setStoreCategories = storeCategories => ({
  type: SET_STORE_CATEGORIES,
  payload: storeCategories,
});

export const setSelectedCategory = category => ({
  type: SET_SELECTED_CATEGORY,
  payload: category,
});

export const setSelectedSubCategory = subcategory => ({
  type: SET_SELECTED_SUBCATEGORY,
  payload: subcategory,
});

export const setLastStoreItemPosition = lastStoreItemPosition => ({
  type: SET_LAST_STORE_ITEM_POSITION,
  payload: lastStoreItemPosition,
});

export const setQueryFilter = queryFilter => ({
  type: SET_QUERY_FILTER,
  payload: queryFilter,
});
