// store
export const SET_STORE_ITEMS = 'SET_STORE_ITEMS';
export const SET_STORE_CATEGORIES = 'SET_STORE_CATEGORIES';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_SELECTED_SUBCATEGORY = 'SET_SELECTED_SUBCATEGORY';
export const SET_LAST_STORE_ITEM_POSITION = 'SET_LAST_STORE_ITEM_POSITION';
export const SET_QUERY_FILTER = 'SET_QUERY_FILTER';
export const UPDATE_STORE_ITEM = 'UPDATE_STORE_ITEM';
export const REMOVE_STORE_ITEM = 'REMOVE_STORE_ITEM';
export const UPDATE_STOCK_CART_ITEM = 'UPDATE_STOCK_CART_ITEM';

// shoppingCart
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const EMPTY_CART = 'EMPTY_CART';
export const SET_CART_ITEMS_UPDATED = 'SET_CART_ITEMS_UPDATED';
export const MARK_CART_ITEM_AS_CHECK = 'MARK_CART_ITEM_AS_CHECK';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const SET_MEASURE = 'SET_MEASURE';
export const SET_DISCOUNT_RULES = 'SET_DISCOUNT_RULES';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_DELIVERY_INFO = 'SET_DELIVERY_INFO';
export const SET_STORE = 'SET_STORE';
export const SET_SHIPPING_INFO = 'SET_SHIPPING_INFO';
export const SET_PICKUP_INFO = 'SET_PICKUP_INFO';
export const SET_SHIPPING_AVAILABLE = 'SET_SHIPPING_AVAILABLE';
export const SET_SHIPPING_SETTINGS = 'SET_SHIPPING_SETTINGS';
export const SET_SHIPPING_COST = 'SET_SHIPPING_COST';
export const SET_EXTRA_NOTES = 'SET_EXTRA_NOTES';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_CASH_OPTION = 'SET_CASH_OPTION';
export const SET_CUSTOMER_PAYMENT_METHODS = 'SET_CUSTOMER_PAYMENT_METHODS';
export const SET_ORDER_STATE = 'SET_ORDER_STATE';
export const SET_CUSTOMER_CARD = 'SET_CUSTOMER_CARD';
export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART';
export const SET_ORDER_MESSAGE = 'SET_ORDER_MESSAGE';
export const SELECT_RIPENESS = 'SELECT_RIPENESS';
export const SET_DELIVERY_SCHEDULE_SLOT = 'SET_DELIVERY_SCHEDULE_SLOT';
export const SET_DELIVERY_TIME = 'SET_DELIVERY_TIME';
export const SET_DELIVERY_DAY = 'SET_DELIVERY_DAY';
export const SET_STRIPE_PAYMENT_METHOD = 'SET_STRIPE_PAYMENT_METHOD';

// States
export const ORDER_STATE_INITIALIZED = 'ORDER_STATE_INITIALIZED';
export const ORDER_STATE_PROCESSING = 'ORDER_STATE_PROCESSING';
export const ORDER_STATE_SUCCEEDED = 'ORDER_STATE_SUCCEEDED';
export const ORDER_STATE_DISABLED = 'ORDER_STATE_DISABLED';
export const ORDER_STATE_ERROR = 'ORDER_STATE_ERROR';

export const SUBMIT_ORDER_TRIGGER_STATE_PENDING = 'SUBMIT_ORDER_TRIGGER_STATE_PENDING';
export const SUBMIT_ORDER_TRIGGER_STATE_READY = 'SUBMIT_ORDER_TRIGGER_STATE_READY';
