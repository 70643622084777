import {
  SET_STORE_ITEMS,
  UPDATE_STORE_ITEM,
  REMOVE_STORE_ITEM,
  SET_STORE_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUBCATEGORY,
  SET_LAST_STORE_ITEM_POSITION,
  SET_QUERY_FILTER,
} from '../types';

/**
 * @param {Objext} obj
 * @param {Array} storeItems Products in the storefront
 * @param {Array} storeCategories Categories available from the client
 * @param {Object} lastStoreItemPosition The position of last product added in the storefront
 * @param {selectedCategory} selectedCategory Current selected category in the storefront
 * @param {selectedSubCategory} selectedSubCategory Current selected subcategory in the storefront
 * @param {String} queryFilter Current query string from the search box
 */
export const initialState = {
  storeItems: [],
  storeCategories: [],
  lastStoreItemPosition: 0.0,
  selectedCategory: 'All',
  selectedSubCategory: 'All',
  queryFilter: '',
};

/**
 * Storefront reducer
 * @param {Constant} state
 * @param {Object} action
 */

const storeItemModel = item => {
  return {
    __typename: 'StoreItems',
    id: item?.id,
    name: item?.name,
    measure: item?.measure,
    price: item?.price,
    unit: item?.unit,
    image_url: item?.image_url,
    files: item?.files,
    category: {
      __typename: 'Category',
      name: item?.category?.name,
    },
    subcategory: item?.subcategory,
    isInStock: item?.isInStock,
    minimum: item?.minimum,
    weight: item?.weight,
    description: item?.description,
    has_ripeness: item?.has_ripeness,
  };
};

const storefrontReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_ITEMS: {
      return {
        ...state,
        storeItems: action.payload,
      };
    }
    case UPDATE_STORE_ITEM: {
      let storeItems = Object.assign([], state.storeItems);
      const storeItemIndex = state.storeItems.findIndex(storeItem => parseInt(storeItem.id) === action.payload.id);

      // Add item if not exist, add
      if (storeItemIndex === -1 && action.payload.isInStock) {
        storeItems.push(action.payload);
        storeItems = storeItems.sort((a, b) => {
          const aS = a.name || a.titles;
          const bS = b.name || b.titles;
          return (aS > bS) - (aS < bS);
        });
      }

      // If item exist and is active, update
      else if (storeItemIndex !== -1 && action.payload.isInStock) {
        storeItems[storeItemIndex] = storeItemModel(action.payload);
      }

      // If item exist and is not active, remove
      else if (storeItemIndex !== -1 && !action.payload.isInStock) {
        storeItems.splice(storeItemIndex, 1);
      }

      const newState = {
        ...state,
        storeItems,
      };
      return newState;
    }
    case REMOVE_STORE_ITEM: {
      let storeItems = [...state.storeItems];
      storeItems = storeItems.filter(storeItem => parseInt(storeItem.id) !== action.payload.id);
      return {
        ...state,
        storeItems,
      };
    }
    case SET_STORE_CATEGORIES: {
      return {
        ...state,
        storeCategories: action.payload,
      };
    }
    case SET_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    }
    case SET_SELECTED_SUBCATEGORY: {
      return {
        ...state,
        selectedSubCategory: action.payload,
      };
    }
    case SET_LAST_STORE_ITEM_POSITION: {
      return {
        ...state,
        lastStoreItemPosition: action.payload,
      };
    }
    case SET_QUERY_FILTER: {
      return {
        ...state,
        queryFilter: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default storefrontReducer;
