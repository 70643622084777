import React, { useEffect } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import imgHeader from 'assets/img/illustrations/store-off-header.png';
import imgFooter from 'assets/img/illustrations/store-off-footer.png';
import { useAppContext } from 'context/Context';

const Error410 = () => {

  const history = useHistory();

  const {
    store,
  } = useAppContext();

  // const ORCHATA_STORE = window.location.host === "app.orchata.com";
  const ORCHATA_STORE = window.location.host === "local.orchata.com:3001";

  const redirectToHome = () => {
    if(window.location.host === "app.orchata.com" && store?.isActive){
      history.push('/');
    }
  }

  useEffect(redirectToHome, [history, store])

  return (
    ORCHATA_STORE ? (
      <div className="text-center" onClick={() => { history.push('/') }}>
        <img className="w-100 fixed-top" src={imgHeader} alt="header store illustration" />
        <h2 style={{color: "#363F4E"}}>¡Oh no! <br/>Estamos cerrados</h2>
        <img className="w-25 fixed-bottom mx-auto pb-5" src={imgFooter} alt="footer store illustration" />
        <p className="mt-3" style={{color: '#97A1B5'}}>Pero te esperamos mañana  a partir de las 9:00 am. Para mandarte tus productos favoritos en menos de 15 minutos.</p>
      </div>
    ):(
      <Card className="text-center">
        <CardBody className="p-5">
          <div className="display-1 text-200 fs-error">410</div>
          <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Whoops, ¡Algo salio mal!</p>
          <hr />
          <p>
            El link que estas buscando ya expiró
            <br />
            <Button
              color="link"
              onClick={() => {
                history.push('/');
              }}
              className="ml-1 px-0"
            >
              Ir a inicio
            </Button>
            &nbsp;ó
            <a href="mailto:amigos@orchata.com" className="ml-1">
              contáctanos
            </a>
            .
          </p>
        </CardBody>
      </Card>
    )
  )
};

export default Error410;
