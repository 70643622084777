import React, { createContext, useEffect } from 'react';
import io from 'socket.io-client';
import { getBaseApiUrl } from '../helpers/globals';
import { useAppContext } from './Context';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateStoreItem,
  removeStoreItem,
  setStoreCategories,
  setSelectedCategory,
} from '../actions/storefrontActions';
import { updateCartItem, removeCartItem } from '../actions/shoppingCartActions';
import client from 'utils/apolloClient';
import { loader } from 'graphql.macro';

const findStoreItemsQuery = loader('queries/graphql/storeItems/findStoreItems.graphql');

const WebSocketContext = createContext(null);

export { WebSocketContext };

/**
 * Manage the web socket connection
 * @param {Object} obj
 * @param {Function} obj.children
 */
const WebSocket = ({ children }) => {
  let socket;
  const { store, setStore } = useAppContext();
  const dispatch = useDispatch();
  const storeItems = useSelector(state => state.storefront.storeItems);
  const selectedCategory = useSelector(state => state.storefront.selectedCategory);

  if (!socket) {
    socket = io.connect(getBaseApiUrl());

    socket.on('event://connected', msg => {
      if (store.id) socket.emit('event://registerUser', store.id);
    });

    socket.on('event://updateStoreItem', storeItem => {
      const updatedStoreItemId = storeItems.map(item => parseInt(item.id)).indexOf(storeItem.id);

      let updatedStoreItems = [...storeItems];

      if (
        storeItem.isInStock &&
        (!store.only_featured_store_items || (store.only_featured_store_items && storeItem.featured))
      ) {
        // Add or update store item

        dispatch(updateStoreItem(storeItem));
        dispatch(updateCartItem(storeItem));

        updatedStoreItems[updatedStoreItemId !== -1 ? updatedStoreItemId : updatedStoreItems.length] = {
          ...storeItem,
          __typename: 'StoreItems',
          id: storeItem.id?.toString(),
        };
      } else if (updatedStoreItemId !== -1) {
        // Delete store item

        dispatch(removeStoreItem(storeItem));
        if (!storeItem.isInStock) dispatch(removeCartItem(storeItem));

        updatedStoreItems.splice(updatedStoreItemId, 1);
      }

      client.writeQuery({
        query: findStoreItemsQuery,
        data: {
          storeItems: updatedStoreItems,
        },
      });
    });

    // TODO: Update categories in graphql
    socket.on('event://setStoreCategories', storeCategories => {
      // Update storeCategories
      dispatch(setStoreCategories(storeCategories));

      // Change default category
      const categoryNames = storeCategories.map(storeCategory => {
        return storeCategory?.category?.name;
      });
      if (!categoryNames.includes(selectedCategory)) {
        dispatch(setSelectedCategory(categoryNames?.[0]));
      }

      // Disable items from cart
      const categoryIds = storeCategories.map(storeCategory => {
        return storeCategory?.category?.id;
      });
      const nonCategoriesStoreItems = storeItems.filter(storeItem => {
        return !categoryIds.includes(storeItem?.category?.id);
      });
      nonCategoriesStoreItems.forEach(storeItem => {
        storeItem.isInStock = false;
        dispatch(updateCartItem(storeItem));
      });
    });

    socket.on('event://updateBusiness', business => {
      setStore(business);
    });
  }

  useEffect(() => {
    return () => {
      if (socket) socket.disconnect();
    };
  });

  return (
    <WebSocketContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocket;
