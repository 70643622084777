import clientAxios from '../config/axios';
import { isDemo } from '../Main';
import { getQueryParams } from '../helpers/utils';

export const getStores = async () => {
  try {
    const path = `/businesses?&_sort=domain:ASC&_limit=500&isActive=true`;
    const result = await clientAxios.get(path);
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const getStoreItems = async ({ id, isSaas }) => {
  try {
    const path = isSaas
      ? `/store-items?business=${id}&_sort=name:ASC&_limit=500`
      : `products?_sort=name:ASC&_limit=500`;
    const result = await clientAxios.get(path);
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const getStoreCategories = async ({ id }) => {
  try {
    const path = `/store-categories?business=${id}&isActive=true&_sort=order:ASC`;
    const result = await clientAxios.get(path);
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const getBusiness = async ({ domain, demoId }) => {
  try {
    let demoStore = {};
    if (isDemo()) {
      let query = getQueryParams(document.location.search);
      let uuid = query.uuid || demoId;
      if (uuid) {
        if (uuid === 'default') {
          domain = 'midemo.mx';
        } else {
          const result = await clientAxios.get(`/demos?uuid=${uuid}&isActive=true`);
          const demo = result?.data?.[0];
          if (demo) {
            domain = demo.storeType
              ? `${demo.storeType}.${domain
                  ?.split('.')
                  ?.slice(-2)
                  ?.join('.')}`
              : 'midemo.mx';
            // override store values
            demoStore = {
              Name: demo.Name,
              mainColor: demo.mainColor,
              logo: demo.logo,
              isActive: demo.isActive,
              whatsapp: demo.whatsapp,
            };
          }
        }
      }
    }
    const result = await clientAxios.get(`/businesses/domain/${domain}`);
    let store = result.data;
    store.business = {
      ...store.business,
      ...demoStore,
    };
    return store;
  } catch (err) {
    console.error(err);
  }
};
