import {
  ADD_CART_ITEM,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEMS,
  REMOVE_CART_ITEM,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  EMPTY_CART,
  SET_CART_ITEMS_UPDATED,
  SET_MEASURE,
  SET_DISCOUNT,
  SET_DISCOUNT_RULES,
  SET_DELIVERY_TYPE,
  SET_DELIVERY_INFO,
  SET_STORE,
  SET_SHIPPING_AVAILABLE,
  SET_SHIPPING_INFO,
  SET_PICKUP_INFO,
  SET_SHIPPING_COST,
  SET_CUSTOMER,
  SET_SHIPPING_SETTINGS,
  SET_EXTRA_NOTES,
  SET_PAYMENT_METHOD,
  SET_CASH_OPTION,
  SET_CUSTOMER_PAYMENT_METHODS,
  SET_CUSTOMER_CARD,
  SET_ORDER_STATE,
  SET_CART_ITEMS,
  MARK_CART_ITEM_AS_CHECK,
  CLEAR_SHOPPING_CART,
  SET_ORDER_MESSAGE,
  SELECT_RIPENESS,
  SET_DELIVERY_SCHEDULE_SLOT,
  SET_DELIVERY_TIME,
  SET_DELIVERY_DAY,
  SET_STRIPE_PAYMENT_METHOD,
  UPDATE_STOCK_CART_ITEM,
} from '../types';

export const addCartItem = storeItem => ({
  type: ADD_CART_ITEM,
  payload: storeItem,
});

export const updateCartItem = storeItem => ({
  type: UPDATE_CART_ITEM,
  payload: storeItem,
});

export const updateStockCartItem = storeItem => ({
  type: UPDATE_STOCK_CART_ITEM,
  payload: storeItem,
});

export const updateCartItems = storeItems => ({
  type: UPDATE_CART_ITEMS,
  payload: storeItems,
});

export const removeCartItem = id => ({
  type: REMOVE_CART_ITEM,
  payload: id,
});

export const setCartItems = cartItems => ({
  type: SET_CART_ITEMS,
  payload: cartItems,
});

export const emptyCart = () => ({
  type: EMPTY_CART,
});

export const setCartItemsUpdated = cartItemsUpdated => ({
  type: SET_CART_ITEMS_UPDATED,
  payload: cartItemsUpdated,
});

export const markCartItemAsCheck = id => ({
  type: MARK_CART_ITEM_AS_CHECK,
  payload: id,
});

export const selectRipeness = ({ id, ripeness }) => ({
  type: SELECT_RIPENESS,
  payload: { id, ripeness },
});

export const setDeliveryScheduleSlot = deliveryScheduleSlot => ({
  type: SET_DELIVERY_SCHEDULE_SLOT,
  payload: { deliveryScheduleSlot },
});

export const setDeliveryTime = deliveryTime => ({
  type: SET_DELIVERY_TIME,
  payload: { deliveryTime },
});

export const setDeliveryDay = deliveryDay => ({
  type: SET_DELIVERY_DAY,
  payload: { deliveryDay },
});

export const increaseQuantity = id => ({
  type: INCREASE_QUANTITY,
  payload: id,
});

export const decreaseQuantity = id => ({
  type: DECREASE_QUANTITY,
  payload: id,
});

export const setMeasure = (id, measure) => ({
  type: SET_MEASURE,
  payload: { id, measure },
});

export const setDiscount = ({ discount, discountLabels }) => ({
  type: SET_DISCOUNT,
  payload: { discount, discountLabels },
});

export const setDiscountRules = discountRules => ({
  type: SET_DISCOUNT_RULES,
  payload: discountRules,
});

export const setDeliveryType = deliveryType => ({
  type: SET_DELIVERY_TYPE,
  payload: deliveryType,
});

export const setDeliveryInfo = deliveryInfo => ({
  type: SET_DELIVERY_INFO,
  payload: deliveryInfo,
});

export const setStore = store => ({
  type: SET_STORE,
  payload: store,
});

export const setCustomer = ({ shippingName, shippingWhatsapp }) => ({
  type: SET_CUSTOMER,
  payload: { shippingName, shippingWhatsapp },
});

export const setShippingInfo = ({ shippingAddress, shippingCoordinates, shippingLocality, shippingExtra }) => ({
  type: SET_SHIPPING_INFO,
  payload: { shippingAddress, shippingCoordinates, shippingLocality, shippingExtra },
});

export const setPickupInfo = ({ shippingName, shippingWhatsapp, pickupDateTime }) => ({
  type: SET_PICKUP_INFO,
  payload: { shippingName, shippingWhatsapp, pickupDateTime },
});

export const setExtraNotes = extraNotes => ({
  type: SET_EXTRA_NOTES,
  payload: extraNotes,
});

export const setPaymentMethod = paymentMethod => ({
  type: SET_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const setCashOption = cashOption => ({
  type: SET_CASH_OPTION,
  payload: cashOption,
});

export const setShippingAvailable = shippingAvailable => ({
  type: SET_SHIPPING_AVAILABLE,
  payload: shippingAvailable,
});

export const setShippingCost = shippingCost => ({
  type: SET_SHIPPING_COST,
  payload: shippingCost,
});

export const setShippingSettings = ({ staticShippingCost, hasShippingCost, shippingCostType }) => ({
  type: SET_SHIPPING_SETTINGS,
  payload: { staticShippingCost, hasShippingCost, shippingCostType },
});

export const setCustomerPaymentMethods = customerPaymentMethods => ({
  type: SET_CUSTOMER_PAYMENT_METHODS,
  payload: customerPaymentMethods,
});

export const setCustomerCard = customerCard => ({
  type: SET_CUSTOMER_CARD,
  payload: customerCard,
});

export const setOrderState = ({ orderState, orderStateMessage = null }) => ({
  type: SET_ORDER_STATE,
  payload: { orderState, orderStateMessage },
});

export const clearShoppingCart = () => ({
  type: CLEAR_SHOPPING_CART,
});

export const setOrderMessage = ({ orderMessage }) => ({
  type: SET_ORDER_MESSAGE,
  payload: { orderMessage },
});

export const setStripePaymentMethod = ({ stripePaymentMethod }) => ({
  type: SET_STRIPE_PAYMENT_METHOD,
  payload: { stripePaymentMethod },
});
