/**
 * Manage the state migration of the local storage depending of version, compatibleVersions and incompatibleStateKeys defined
 * in package.json and lastInstalledVersion item in local storage
 *
 * 1. If the installed version is present follow these steps:
 * 1.1 Migrate the local storage keys from last installed version if is listed in compatibleVersions e.g. "redux::1.0.1::cartItems"
 * 1.2 Exclude the incompatibleStateKeys e.g. shoppingCart.cartItems
 * 1.3 The incompatibleStateKeys are set to initialState
 *
 * 2. If the installed version is not present follow these steps:
 * 2.1 Update the compatibleStateKeys from the non versioned local storage entry e.g. redux_simple_local_storage_shoppingCart
 * 2.2 Remove all non versioned entries e.g. redux_simple_local_storage_shoppingCart
 *
 * 3. Use a redux middleware to load / save state from / to localstorage u
 *
 * 4. Update the last installed version entry in localstorage
 *
 * 5. Delete old version entries form local storate. e.g. redux::1.0.0::cartItems
 */
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers';
import { save, load } from 'redux-localstorage-simple';
import { version, compatibleVersions, incompatibleStateKeys } from '../package.json';
import { initialState as shoppingCartInitialState } from './reducers/shoppingCartReducer';

// The reducers that are stored in local storage
const reducers = {
  shoppingCart: shoppingCartInitialState,
};

// Get the lastInstalled version from local storage
const lastInstalledVersion = localStorage.getItem('lastInstalledVersion');

const stateMigrations = {
  'shoppingCart.cartItemsCount': localStorage.getItem(`redux::${lastInstalledVersion}::shoppingCart.cartItems`)
    ? Object.keys(JSON.parse(localStorage.getItem(`redux::${lastInstalledVersion}::shoppingCart.cartItems`))).length
    : 0,
};

// Migrate local storage keys from lastInstalledVersion to version only if is compatible versions except incompatibleStateKeys
if (lastInstalledVersion) {
  if (lastInstalledVersion !== version && compatibleVersions.includes(lastInstalledVersion)) {
    for (const [stateGroup, initialState] of Object.entries(reducers)) {
      // Migrate from last installed version if is compatible
      const compatibleStates = Object.keys(initialState).filter(
        stateName => !incompatibleStateKeys.includes(`${stateGroup}.${stateName}`),
      );

      compatibleStates.forEach(stateName => {
        if (
          Object.keys(stateMigrations).includes(`${stateGroup}.${stateName}`) &&
          !localStorage.getItem(`redux::${version}::${stateGroup}.${stateName}`)
        ) {
          localStorage.setItem(
            `redux::${version}::${stateGroup}.${stateName}`,
            stateMigrations[`${stateGroup}.${stateName}`],
          );
        } else if (localStorage.getItem(`redux::${lastInstalledVersion}::${stateGroup}.${stateName}`)) {
          localStorage.setItem(
            `redux::${version}::${stateGroup}.${stateName}`,
            localStorage.getItem(`redux::${lastInstalledVersion}::${stateGroup}.${stateName}`),
          );
        } else {
          localStorage.setItem(
            `redux::${version}::${stateGroup}.${stateName}`,
            JSON.stringify(initialState[stateName]),
          );
        }
      });

      // Set incompatible states to initial state
      const incompatibleStates = Object.keys(initialState).filter(state =>
        incompatibleStateKeys.includes(`${stateGroup}.${state}`),
      );
      incompatibleStates.forEach(stateName => {
        localStorage.setItem(`redux::${version}::${stateGroup}.${stateName}`, JSON.stringify(initialState[stateName]));
      });
    }
  }
} else {
  // Load from non versioned local storage
  for (const [stateGroup, initialState] of Object.entries(reducers)) {
    const compatibleStates = Object.keys(initialState).filter(
      stateName => !incompatibleStateKeys.includes(`${stateGroup}.${stateName}`),
    );
    const state = JSON.parse(localStorage.getItem(`redux_localstorage_simple_${stateGroup}`));
    if (state) {
      compatibleStates.forEach(stateName => {
        if (state[stateName])
          localStorage.setItem(`redux::${version}::${stateGroup}.${stateName}`, JSON.stringify(state[stateName]));
      });
    }

    // Remove all non versioned local storage entries
    localStorage.removeItem(`redux_localstorage_simple_${stateGroup}`);
  }
}

// Get all states from initialState
let states = [];
for (const [stateGroup, initialState] of Object.entries(reducers)) {
  states = states.concat(Object.keys(initialState).map(state => `${stateGroup}.${state}`));
}

// Save local storage through redux middleware
const createStoreWithMiddleware = applyMiddleware(
  save({
    states,
    namespace: `redux::${version}`,
    namespaceSeparator: '::',
  }),
)(createStore);

// Load local storage through redux middleware
const store = createStoreWithMiddleware(
  rootReducer,
  load({
    states,
    namespace: `redux::${version}`,
    namespaceSeparator: '::',
    disableWarnings: true,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

// Update lastInstalled version if is different
if (lastInstalledVersion !== version) localStorage.setItem('lastInstalledVersion', version);

// Delete old version entries form local storate.
Object.keys(localStorage).forEach(key => {
  if (key.substr(0, 7) === 'redux::') {
    const stateVersion = key.split('::')[1];
    if (stateVersion !== version /* && !compatibleVersions.includes(stateVersion)*/) {
      localStorage.removeItem(key);
    }
  }
});

export default store;
