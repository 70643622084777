import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import Footer from 'components/footer/Footer';
import { isMobile } from 'react-device-detect';

const StoreRoutes = loadable(() => import('./StoreRoutes'));

const StorefrontLayout = () => {
  return (
    <>
      <div className={`wrapper ${isMobile ? 'container-fluid mx-0 px-0' : 'container'}`}>
        <Switch>
          <StoreRoutes />
          {/*Redirect*/}
          <Redirect to="/errors/" />
        </Switch>
        <div className="push" />
      </div>
      {
        process.env.REACT_APP_STAGE !== 'production' &&
        <Footer />
      }
    </>
  );
};

StorefrontLayout.propTypes = { match: PropTypes.object };

export default StorefrontLayout;
