// A library reused throughout the entire application
import algoliasearch from 'algoliasearch/lite';

/* ***** API - URL ***** */

const API_URL_DEV = process.env.REACT_APP_API_HOST || 'http://localhost:1337';
const API_URL_STAGING = 'https://staging.api.orchata.com';
const API_URL_PROD = 'https://api.orchata.com';

export const getBaseApiUrl = () => {
  if (process.env.REACT_APP_STAGE === 'production') return API_URL_PROD;
  else if (process.env.REACT_APP_STAGE === 'staging' || process.env.REACT_APP_STAGE === 'deploy-preview')
    return API_URL_STAGING;
  else return API_URL_DEV;
};

export const STRIPE_URL =
  process.env.REACT_APP_STAGE === 'production'
    ? 'https://planes.orchata.com'
    : process.env.REACT_APP_STAGE === 'staging' || process.env.REACT_APP_STAGE === 'deploy-preview'
    ? 'https://staging.planes.orchata.com'
    : process.env.REACT_APP_PAYMENT_HOST || 'http://localhost:5000';

export const algoliaSearchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY,
);

const encodeQueryData = data => {
  const ret = [];
  for (let d in data) {
    if (data.hasOwnProperty(d)) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
};

export const createGeneralApiUrl = (base, route = '', params = {}) => {
  const paramsString = encodeQueryData(params);
  return `${base}${route}${paramsString ? '?' + paramsString : ''}`;
};

export const createApiUrl = (route = '', params = {}) => {
  return createGeneralApiUrl(getBaseApiUrl(), route, params);
};

export const wait = ms => {
  return new Promise((r, j) => setTimeout(r, ms));
};

/* ***** DATE ***** */

const days = {
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miercoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sabado',
};

export const getDayOfWeek = () => {
  const day = new Date().getDay();
  return days[day];
};

const fallbackCopyTextToClipboard = text => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function() {},
    function(err) {
      console.error('Async: Could not copy text: ', err);
    },
  );
};

/* ***** DEVICES & BROWSERS ***** */

// Source: https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
const _localStorageIsAvailable = () => {
  let test = 'lstest';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};
export const localStorageIsAvailable = _localStorageIsAvailable();

/* ***** OTHER ***** */
export function calculateWindowScrollPos() {
  return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
}
