export const identifyWithSegment = params => {
  if (!params?.storefront?.isTrial & !params?.storefront?.is_demo) {
    window.analytics.identify(params.whatsapp, params.properties);
  }
};

export const trackWithSegment = params => {
  if (!params?.storefront?.isTrial & !params?.storefront?.is_demo) {
    window.analytics.track(params.event, params.properties);
  }
};
